.main {
    background-color: rgb(240, 235, 235);
    padding: 4%
}

.main-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
}

.description {
    text-align: center;
    padding: 0 5% 0 5% !important;   
}

.sub-title {
    text-align: center;
    margin-bottom: 30px;
}

.services {
    margin-top: 50px;
}

.img {
    width: 200px;
    height: 200px;
    margin: auto;    
    display: block;
}

.sub-desc {
    text-align: center;
    margin-top: 40px;
}