.item:hover{
    background-color: #0bc521 !important;
    cursor: pointer;
    border-radius: 10px;
}

.item {
    font-size: 18px;
    margin-bottom: 16px;
    color: white;
    border-radius: 10px !important;
    align-items: center !important;
    display:inherit;
    text-align: center;
}

.list-group-sidebar {
    height: 30rem;
}

.icon-fas {
    margin-right: 30px;
    margin-left: 30px;
}

.link-contain {
    background-color: #53a83d !important;
}