
.farmerContainer {
    margin-top: 130px !important;
    margin-bottom: 30px !important;
}

.farmer-title {
    text-align: center;
    font-size: 22px;
}

.card-titile {
    text-align: center;
    margin-bottom: 20px;
}

.title {
    text-align: center; 
    font-weight: bold; 
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.btn-explore {
    margin-bottom: 10px;
    text-align: center;
    align-items: center;
    margin-left: 25% !important;
  }