.title
{
    cursor: pointer !important;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 22px;
    text-decoration: none;
    font-weight: 400 !important;
}

.btn-preview {
    text-align: center;
    align-items: center;
    margin-left: 25% !important;
}
