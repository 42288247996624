.seed-product
{
    margin-top:30px;
    margin-bottom:50px
}

.btn-go-back
{
    margin-top:20px;
    font-size: 16px;
    background-color:#10c61e
}

.productScreen
{
    margin-top: 100px
}

.image-seed
{
    margin: auto;    
    display: block;
}