.navbar
{
    background-color: #008f11 !important;
    width: auto;
    color: white !important;
}
.nav-cal{
    width: auto;
    color: white !important;
    font-size: 14px;
}

#username, #adminmenu {
    color: white !important;
    font-size: 14px;
}

.cart {
    text-decoration: none !important;
    margin-left: 0rem !important;
    font-size: 14px !important;
    color: white !important;
}

.fa-shopping-cart {
    margin-right: 8px ;
    color: white;
}

/* Desktop */
@media only screen and (min-width: 992px) {
    .navbar {
        height: 90px;      
    }
    .cart {
        float: right !important;
        margin-left: 35rem !important;
        color: white !important;
        font-size: 14px !important;
        font-size: 14px;
    }
    .remove-space {
        float: right !important;
        margin-left: 35rem !important;
        color: white !important;
        font-size: 14px !important;
    }
    .nav-cal{
        margin-left: 10px;
    }
}

/* Phone */
@media only screen and (max-width:320px) {
    .nav-cal{
        font-size: 15px !important;
    }
}