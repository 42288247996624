
.supplierContainer {
    margin-top: 120px !important;
    margin-bottom: 50px !important;
}

.supplier-title {
    text-align: center;
    margin-bottom: 5px;
    font-size: 20px;
}

.title {
    text-align: center;
    margin-bottom: 20px;   
    font-weight: bold; 
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}